import React from 'react';
// import {StaticImage} from "gatsby-plugin-image"
import Divider from './Divider';
// import SvgCheckedLabel from './SvgCheckedLabel';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import gmail from '../images/apps/gmail.png';
import slack from '../images/apps/slack.png';
import viber from '../images/apps/viber.png';
import whatsapp from '../images/apps/whatsapp.png';
import telegram from '../images/apps/telegram.png';
import zalo from '../images/apps/zalo.png';
import jira from '../images/apps/jira.png';
import trello from '../images/apps/trello.png';
import basevn from '../images/apps/basevn.png';
import misa from '../images/apps/misa.png';
import twitter from '../images/apps/twitter.png';
import facebookmessenger from '../images/apps/facebook-messenger.png';
import gcp from '../images/apps/gcp.png';
import googlesheets from '../images/apps/google-sheets.png';
import googlecalendar from '../images/apps/google-calendar.png';
import googledocs from '../images/apps/google-docs.png';
import googlefirebase from '../images/apps/google-firebase.png';
import mailchimp from '../images/apps/mailchimp.png';
import salesforce from '../images/apps/salesforce.png';
import mysql from '../images/apps/mysql.png';
import airtable from '../images/apps/airtable.png';
import microsoftteams from '../images/apps/microsoft-teams.png';
import microsoftonedrive from '../images/apps/microsoft-onedrive.png';
import microsoftsharepoint from '../images/apps/microsoft-sharepoint.png';
import microsoftazure from '../images/apps/microsoft-azure.png';
import mssql from '../images/apps/mssql.png';
import twillio from '../images/apps/twillio.png';
import instagram from '../images/apps/instagram.png';
import evernote from '../images/apps/evernote.png';
import zendesk from '../images/apps/zendesk.png';
import magento from '../images/apps/magento.png';
import aftership from '../images/apps/aftership.png';
import github from '../images/apps/github.png';
import bitbucket from '../images/apps/bitbucket.png';
import splunk from '../images/apps/splunk.png';
import weatherunderground from '../images/apps/weather-underground.png';
import aws from '../images/apps/aws.png';
import stripe from '../images/apps/stripe.png';
import typeform from '../images/apps/typeform.png';
import mongodb from '../images/apps/mongodb.png';
import oracledb from '../images/apps/oracle-db.png';
import discord from '../images/apps/discord.png';
import dropbox from '../images/apps/dropbox.png';
import box from '../images/apps/box.png';
import eventbrite from '../images/apps/eventbrite.png';
import excel from '../images/apps/excel.png';
import googledrive from '../images/apps/google-drive.png';
import qrcode from '../images/apps/qrcode.png';
import redmine from '../images/apps/redmine.png';

const ConnectorsTable = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 pt-10 mb-5 body-font">
      <div className="my-cont">
        <Divider title={t('table.title')} />
        <div className="text-center mb-10">
          <p className="leading-relaxed lg:w-3/5 mx-auto text-xl text-blue-900">{t('table.tag')}</p>
        </div>
        <div className="flex flex-wrap -m-2">
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={slack} />
              <h2 className="text-gray-900 title-font font-medium">Slack</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftteams} />
              <h2 className="text-gray-900 title-font font-medium">Microsoft Teams</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={viber} />
              <h2 className="text-gray-900 title-font font-medium">Viber</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={whatsapp} />
              <h2 className="text-gray-900 title-font font-medium">Whatsapp</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={facebookmessenger} />
              <h2 className="text-gray-900 title-font font-medium">Facebook Messenger</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={telegram} />
              <h2 className="text-gray-900 title-font font-medium">Telegram</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex relative items-center border-gray-200 border p-4 rounded-lg">
              <span className="bg-yellow-200 text-blue-900 px-3 py-1 tracking-widest text-xs absolute right-1 top-1 rounded">Coming soon</span>
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={zalo} />
              <h2 className="text-gray-900 title-font font-medium">Zalo</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={gmail} />
              <h2 className="text-gray-900 title-font font-medium">Gmail</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={jira} />
              <h2 className="text-gray-900 title-font font-medium">Jira</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={trello} />
              <h2 className="text-gray-900 title-font font-medium">Trello</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex relative items-center border-gray-200 border p-4 rounded-lg">
              <span className="bg-yellow-200 text-blue-900 px-3 py-1 tracking-widest text-xs absolute right-1 top-1 rounded">Coming soon</span>
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={basevn} />
              <h2 className="text-gray-900 title-font font-medium">Base.vn</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex relative items-center border-gray-200 border p-4 rounded-lg">
              <span className="bg-yellow-200 text-blue-900 px-3 py-1 tracking-widest text-xs absolute right-1 top-1 rounded">Coming soon</span>
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={misa} />
              <h2 className="text-gray-900 title-font font-medium">MISA</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={twitter} />
              <h2 className="text-gray-900 title-font font-medium">Twitter</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={twillio} />
              <h2 className="text-gray-900 title-font font-medium">Twillio</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={stripe} />
              <h2 className="text-gray-900 title-font font-medium">Stripe</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={excel} />
              <h2 className="text-gray-900 title-font font-medium">Excel, ODS</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={gcp} />
              <h2 className="text-gray-900 title-font font-medium">Google Cloud Platform</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={googlesheets} />
              <h2 className="text-gray-900 title-font font-medium">Google Sheets</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={googlecalendar} />
              <h2 className="text-gray-900 title-font font-medium">Google Calendar</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={googledocs} />
              <h2 className="text-gray-900 title-font font-medium">Google Docs</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={googledrive} />
              <h2 className="text-gray-900 title-font font-medium">Google Drive</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={googlefirebase} />
              <h2 className="text-gray-900 title-font font-medium">Google Firebase</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={mailchimp} />
              <h2 className="text-gray-900 title-font font-medium">Mailchimp</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={salesforce} />
              <h2 className="text-gray-900 title-font font-medium">Salesforce</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={mysql} />
              <h2 className="text-gray-900 title-font font-medium">MySQL</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={mongodb} />
              <h2 className="text-gray-900 title-font font-medium">Mongodb</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={oracledb} />
              <h2 className="text-gray-900 title-font font-medium">Oracle DB</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={airtable} />
              <h2 className="text-gray-900 title-font font-medium">Airtable</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={eventbrite} />
              <h2 className="text-gray-900 title-font font-medium">Eventbrite</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={qrcode} />
              <h2 className="text-gray-900 title-font font-medium">QR code</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={mssql} />
              <h2 className="text-gray-900 title-font font-medium">Microsoft SQL</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftsharepoint} />
              <h2 className="text-gray-900 title-font font-medium">Microsoft Sharepoint</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftonedrive} />
              <h2 className="text-gray-900 title-font font-medium">Microsoft OneDrive</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={instagram} />
              <h2 className="text-gray-900 title-font font-medium">Instagram</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={evernote} />
              <h2 className="text-gray-900 title-font font-medium">Evernote</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={zendesk} />
              <h2 className="text-gray-900 title-font font-medium">Zendesk</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={magento} />
              <h2 className="text-gray-900 title-font font-medium">Magento</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aftership} />
              <h2 className="text-gray-900 title-font font-medium">Aftership</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={github} />
              <h2 className="text-gray-900 title-font font-medium">Github</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={bitbucket} />
              <h2 className="text-gray-900 title-font font-medium">Bitbucket</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={splunk} />
              <h2 className="text-gray-900 title-font font-medium">Splunk</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={weatherunderground} />
              <h2 className="text-gray-900 title-font font-medium">Weather Underground</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure AD</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure Application Insights</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure Blob Storage</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure Data Lake Storage</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure DocumentDB</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure Event Hub</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure IoT Central</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure Service Bus</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure SQL</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure Storage</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={microsoftazure} />
              <h2 className="text-gray-900 title-font font-medium">Azure Cognitive</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon SES</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon S3</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon DynamoDB</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon Cognito</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon SNS</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon EC2</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon Lambda</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon Kinesis</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon RDS</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={aws} />
              <h2 className="text-gray-900 title-font font-medium">Amazon Firehose</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={typeform} />
              <h2 className="text-gray-900 title-font font-medium">Typeform</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={discord} />
              <h2 className="text-gray-900 title-font font-medium">Discord</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={dropbox} />
              <h2 className="text-gray-900 title-font font-medium">Dropbox</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={box} />
              <h2 className="text-gray-900 title-font font-medium">Box</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img alt="" className="w-16 h-16 object-cover object-center flex-shrink-0 mr-4" src={redmine} />
              <h2 className="text-gray-900 title-font font-medium">Redmine</h2>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg bg-gray-100">
              <h2 className="text-gray-900 title-font font-medium">{t('table.more')}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectorsTable;
